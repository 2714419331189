import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { MRT_PaginationState } from 'material-react-table';
import { deleteGroupMember, getGroupMembers } from '@administration/apis/groups';
import { IGroupMembers } from '@administration/interfaces/IGroups';
import { IUser } from '@common/interfaces/IUser';
import { displayToast } from '@administration/components/toast/toast.slice';

export const fetchGroupMembers = createAsyncThunk(
  'groupMembers/fetchGroupMembers',
  async ({
    groupId,
    query,
    pagination,
  }: {
    groupId: string;
    query?: string;
    pagination: MRT_PaginationState;
  }, { rejectWithValue }) => {
    try {
      const response = await getGroupMembers(
        groupId,
        query,
        pagination.pageIndex,
        pagination.pageSize,
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error instanceof Error ? error.message : String(error));
    }
  },
);

export const deleteMemberFromGroup = createAsyncThunk(
  'groupMembers/deleteMemberFromGroup',
  async ({ groupId, memberId }: { groupId: string; memberId: string }, { rejectWithValue, dispatch }) => {
    try {
      const response = await deleteGroupMember(groupId, memberId);
      if (response.status === 201) {
        return memberId;
      }
      throw new Error('Failed to delete member');
    } catch (error) {
      dispatch(displayToast({ keyMessage: 'GLOBAL_ERROR', type: 'error' }));
      return rejectWithValue(error instanceof Error ? error.message : String(error));
    }
  },
);

const initialState: IGroupMembers & {
  fetchMembersLoading: boolean;
  deleteMemberLoading: boolean;
} = {
  groupMembersLoading: false,
  fetchMembersLoading: false,
  deleteMemberLoading: false,
  groupMembers: [],
  groupMembersCount: 0,
  groupMembersError: null,
  searchQuery: '',
};

const GroupMembersSlice = createSlice({
  name: 'groupMembers',
  initialState,
  reducers: {
    setSearchQuery: (state, action) => {
      state.searchQuery = action.payload;
    },
  },
  extraReducers: (builder) => {
    // Fetch Group Members
    builder
      .addCase(fetchGroupMembers.pending, (state) => {
        state.fetchMembersLoading = true;
        state.groupMembersLoading = true;
        state.groupMembersError = null;
      })
      .addCase(fetchGroupMembers.fulfilled, (state, action) => {
        state.fetchMembersLoading = false;
        state.groupMembersLoading = false;
        state.groupMembers = action.payload.members;
        state.groupMembersCount = action.payload.$count;
      })
      .addCase(fetchGroupMembers.rejected, (state, action) => {
        state.fetchMembersLoading = false;
        state.groupMembersLoading = false;
        state.groupMembersError = action.payload as string | null;
        state.groupMembersCount = 0;
      });

    // Delete Group Member
    builder
      .addCase(deleteMemberFromGroup.pending, (state) => {
        state.deleteMemberLoading = true;
        state.groupMembersError = null;
      })
      .addCase(deleteMemberFromGroup.fulfilled, (state, action) => {
        const memberId = action.payload;
        state.deleteMemberLoading = false;
        state.groupMembers = state.groupMembers.filter(
          (member: IUser) => member.id !== memberId,
        );
        state.groupMembersCount = state.groupMembers.length;
      })
      .addCase(deleteMemberFromGroup.rejected, (state, action) => {
        state.deleteMemberLoading = false;
        state.groupMembersError = action.payload as string | null;
      });
  },
});

export const { setSearchQuery } = GroupMembersSlice.actions;
export default GroupMembersSlice.reducer;
